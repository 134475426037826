<template>
  <v-app class="app-warp">
    <img class="logo" src="@/assets/login/logo_bai.png" @click="$router.push('/h5Number/Home')" alt="">
    <v-banner
      single-line
      height="506"
      class="banner"
    >
      <img src="https://h5.ophyer.cn/official_website/page/bigitalMan/h5-banner-01-1.png" alt="">
    </v-banner>

    <!-- 多终端适配 -->
    <v-card class="card-warp warp1" :flat="true">
      <div class="public-titles">
        <p class="tit-z">多终端适配</p>
        <p class="tit-e">Multi-terminal adaptation</p>
      </div>
      <div class="con">
        <div class="con1 img-d">
          <p>电脑端</p>
          <p>WEB</p>
          <img src="https://h5.ophyer.cn/official_website/page/bigitalMan/h5-warp1-img-01.png" alt="">
        </div>
        <div class="con2">
          <div class=" img-d">
            <p>移动端</p>
            <p>PHONE</p>
            <img src="https://h5.ophyer.cn/official_website/page/bigitalMan/h5-warp1-img-02.png" alt="">
          </div>
          <div class=" img-d">
            <p>移动大屏</p>
            <p>SCREEN</p>
            <img src="https://h5.ophyer.cn/official_website/page/bigitalMan/h5-warp1-img-03.png" alt="">
          </div>
        </div>
      </div>
    </v-card>

    <!-- 多终端适配 -->
    <v-card class="card-warp warp2" :flat="true">
      <div class="public-titles">
        <p class="tit-z">功能介绍</p>
        <p class="tit-e">Function introduction</p>
      </div>
      <div class="con">
        <v-carousel 
          :reverse="false"
          height="100%"
          hide-delimiter-background
          :show-arrows="false"
          :cycle="true"
          >
          <v-carousel-item v-for="(v, k) in warp2Content.list" :key="k">
            <img :src="v.img" alt="">
            <p class="tit">{{ v.title }}</p>
            <p class="text">{{ v.text }}</p>
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-card>

    <!-- 应用场景 -->
    <v-card class="card-warp warp3" :flat="true">
      <div class="public-titles">
        <p class="tit-z">应用场景</p>
        <p class="tit-e">Function introduction</p>
      </div>
      <div class="con">
        <swiper ref="mySwiper" :options="swiperOptionTwo">
          <swiper-slide v-for="(v,k) in lunbo" :key="k">
            <img :src="v.img" />
            <div class="con-d">
              <p>{{ v.title }}</p>
              <p>{{ v.text }}</p>
            </div>
            <!-- <p>{{ v.title }}</p> -->
          </swiper-slide>
        </swiper>
      </div>
    </v-card>

    <H5OfficialWebsiteFooter :color="'balck'" />
  </v-app>
</template>

<script>

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: 'DigitalMan',
  components: {
    Swiper,
    SwiperSlide,
    H5OfficialWebsiteFooter: () => import('@/components/common/h5OfficialWebsiteFooter.vue'),

  },
  data() {
    return {
      warp2Content: {
        hover: 0,
        list: [
          {
            id: 0,
            img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/warp03-img-01.png',
            title: '多种风格的数字人',
            text: '超写实角色、拟真人角色、卡通风格。通过一张照片就可以秒级别生成各类风格的角色效果，可广泛使用在多种平台上'
          },
          {
            id: 1,
            img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/warp03-img-02.png',
            title: '灵动逼真的交互表现',
            text: '支持真人面部驱动、肢体驱动、手指驱动、语音/文本驱动，支持动作、表情、语音内容等感知深度匹配'
          },
          {
            id: 2,
            img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/warp03-img-03.png',
            title: '成熟全面的产品方案',
            text: '提供全面的多端SDK，也提供完善的软件方案，针对不同的场景需求，快速灵活引入虚拟人能力'
          }
        ]
      },
      swiperOptionTwo: {
        slidesPerView: 2.3,
        spaceBetween: 10,
        loop: true,
        // speed: 3000,
        autoplay: false,
        // autoplay : {
        //   delay: 6000
        // },
      },
      lunbo: [
        {
          id: 0,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img01.png',
          title: '元宇宙',
          text: '虚拟空间'
        },
        {
          id: 1,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img02.png',
          title: '汽车',
          text: '虚拟助手'
        },
        {
          id: 2,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img03.png',
          title: '传媒',
          text: '虚拟主持人、虚拟主播'
        },
        {
          id: 3,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img04.png',
          title: '文旅',
          text: '虚拟导游、虚拟讲解员'
        },
        {
          id: 4,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img05.png',
          title: '金融',
          text: '智能客服'
        },
        {
          id: 5,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img06.png',
          title: '医疗',
          text: '心理医生、家庭医生'
        },
        {
          id: 6,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img07.png',
          title: '零售',
          text: '虚拟售货员'
        },
        {
          id: 7,
          img: 'https://h5.ophyer.cn/official_website/page/bigitalMan/lunbo01-img08.png',
          title: '教育',
          text: '虚拟教师'
        }
      ],
    }
  },
  created() {
  },
  methods: {

  },

}
</script>

<style lang="scss" scoped>
.app-warp {
  margin: 0 auto;
  text-align: center;
  background: #0B0C12;
}

.logo {
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 15px;
  width: 120px !important;
  cursor: pointer;
}

.v-banner {
  position: relative;
  ::v-deep .v-banner__text {
    background: #0B0C12;
  }
  img {
    width: 100%;
    height: 100%;
  }
}


.public-titles {
  height: 60px;
  margin: 0 auto 20px;
  position: relative;
  text-align: center;
  .tit-z {
    height: 30px;
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
  }
  .tit-e {
    height: 30px;
    font-size: 20px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    line-height: 30px;
    color: #fff;
    // mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(rgba(0, 0, 255, 0)), to(#d8d5d5));
    mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.4)));
  }
  p {
    margin-bottom: 0;
  }

}

.card-warp {
  padding: 30px 20px;
  background: #0B0C12;
}

.warp1 {
  background: #17181E;
  .con {
    p:nth-of-type(1) {
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FFFFFF;
      line-height: 23px;
      text-align: start;
      margin-bottom: 0;
    }
    p:nth-of-type(2) {
      font-size: 14px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: rgba(255,255,255,0.1);
      line-height: 20px;
      text-align: start;
    }
  }
  .con1 {
    width: 100%;
    height: 200px;
    background: #2F3035;
    position: relative;
    padding: 10px;
    margin-bottom: 0.1vh;
  }
  .con2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    div {
      width: 49.9%;
      height: 200px;
      background: #2F3035;
      position: relative;
      padding: 10px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }
}


.warp2 {
  padding: 30px 45px 60px;
  img {
    width: 289px;
  }
  .tit {
    height: 23px;
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 23px;
    margin-top: 20px;
  }
  .text {
    font-size: 13px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: rgba(255,255,255,0.7);
    line-height: 19px;
  }
  
  .v-window, .v-carousel {
    overflow: inherit !important;
  }
  ::v-deep .v-carousel__controls {
    bottom: -40px !important;
  }
  ::v-deep .v-carousel__controls__item {
    width: 10px;
    height: 10px;
    opacity: 1;
    border: 2px solid rgba(255,255,255,0.4);
    &::before {
      display: none !important;
    }
  }
  ::v-deep .v-btn__content {
    display: none !important;
  }
  ::v-deep .v-item--active {
    width: 10px;
    height: 10px;
    background: #FFFFFF;
    opacity: 1;
  }
}


.warp3 {
  background: #17181E;
  padding: 30px 0;
  ::v-deep .swiper-wrapper {
    margin-left: 10px !important;
  }
  .swiper-slide {
    position: relative;
    img {
      width: 100%;
      position: relative;
      border-radius: 5px 5px 5px 5px;
 
      z-index: 1;
    }
  }
  .con-d {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    border-radius: 5px 5px 5px 5px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    padding: 13px 15px;
    box-sizing: border-box;
    p {
      margin: 0;
      text-align: start;
    }
    p:nth-of-type(1) {
      height: 22px;
      font-size: 15px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: 700;
      color: #FFFFFF;
      line-height: 22px;
      margin-bottom: 5px;
    }
    p:nth-of-type(2) {
      height: 11px;
      font-size: 8px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 12px;
    }
  }
}

</style>
